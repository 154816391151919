<template>
  <b-modal
    id="modal-category-size"
    size="lg"
    centered
    scrollable
    hide-footer
  >
    <template #modal-header>
      <h5 class="modal-title">Size Ring Guide</h5>
      <button class="btn-close-category-size" @click="$bvModal.hide('modal-category-size')">&times;</button>
    </template>
    <template v-if="product">
      <b-img-lazy
        :src="brands.find(el => el.name == product.brand_name).image_url"
        :title="brands.find(el => el.name == product.brand_name).name"
        :alt="brands.find(el => el.name == product.brand_name).name"
        center
        v-if="brands.length"
      />
      <!-- Caption -->
      <p>
        Ukuran cincin emas yang nyaman adalah cincin yang pas di jari dan mudah
        pula untuk dilepas
      </p>
      <!-- Product Category Size Table -->
      <b-table
        id="category-size-table"
        :items="product.category_size"
        :fields="tableFields"
        thead-class="bg-gold text-white"
        responsive
        fixed
        striped
        bordered
      >
      </b-table>
      <!-- Guide for calculate ring size -->
      <p>Ukuran jari dapat berubah tergantung pada cuaca:</p>
      <p>1. Ukur jari anda ketika cuaca hangat (jari dapat mengecil pada pagi hari dan ketika cuaca dingin)</p>
      <p>2. Ukur jari anda sebanyak 3 sampai 4 kali untuk mengurangi kesalahan dalam pengukuran</p>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ModalCategorySize",
  props: ["product"],
  data() {
    return {
      items: [],
      tableFields: [
        {
          key: "size_model.international",
          label: "Size",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "size",
          label: "Keliling (mm)",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "diameter",
          label: "Diameter (mm)",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
    };
  },
  computed: {
    brands() {
      return this.$store.getters.getBrandsList
    }
  },
  mounted() {
    if (this.product)
      this.setItems(this.product.category_size)
  },
  methods: {
    setItems(data) {
      // Set keliling or diameter to "-" if keliling or diameter doesn't exist
      data.map((el) => {
        el.size = el.size ?? "-"
        el.diameter = el.diameter ?? "-"
        return el
      })
      // Sort category size list by size model id
      this.items = data.sort((a, b) => a.size_model.eu - b.size_model.eu);
    }
  },
};
</script>
<style>
#category-size-table.table-striped>tbody>tr:nth-child(odd)>:is(td, th) {
  background-color: #F3E8D4;
}
/* TODO Remove if css selector (.modal .close) is deleted */
.btn-close-category-size {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  background-color: transparent;
  border: 0;
  padding: 1.2rem;
  margin: -1rem -1rem -1rem auto; 
}
@media screen and (max-width: 992px) {
  #modal-category-size {
    font-size: 12px;
  }
}
</style>
